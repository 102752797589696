<mat-card>
    <mat-card-title class="header-bar">
        <span>MAPSTER Data Options</span>
        <button mat-icon-button (click)="close()">
            <fa-icon icon="times" (click)="close()"></fa-icon>
        </button>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content #content>
        @if (properties) {
        <mat-list>
            <mat-list-item>
                <div matListItemTitle>File Name:</div>
                <div matListItemLine>{{ properties.fileName }}</div>
            </mat-list-item>
            <mat-list-item>
                <div matListItemTitle>State and County:</div>
                <div matListItemLine>{{ properties.county }}, {{ properties.state }}</div>
            </mat-list-item>
            <mat-list-item>
                <div matListItemTitle>File Name:</div>
                <div matListItemLine>{{ properties.fileName }}</div>
            </mat-list-item>
            <mat-list-item>
                <div matListItemTitle>Upload Date:</div>
                <div matListItemLine>{{ properties.uploadDate | momentDate }}</div>
            </mat-list-item>
        </mat-list>

        <mat-divider></mat-divider>
        }

        <p>Select one or more MAPSTER data points to download</p>

        <mat-selection-list [formControl]="optionsForm">
            @for (option of (dataOptions() | keyvalue); track option.key) { @if (option.value && option.key !== 'uuid') {
            <mat-list-option [value]="option.key">
                @if (option.key === 'video') {
                {{ optionMap[option.key] }} (Size: {{ properties.fileSizeInBytes | shortNumber }}) } @else {
                {{ optionMap[option.key] }}
                }
            </mat-list-option>
            } }
        </mat-selection-list>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-flat-button color="primary" (click)="openConfirmation()" [disabled]="optionsForm.invalid">
            <mat-icon>download</mat-icon> Download
        </button>
    </mat-card-actions>
</mat-card>
