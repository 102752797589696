import {Component, computed, inject, OnDestroy, signal, WritableSignal} from '@angular/core';
import {BasePopover} from '../base-popover';
import {Survey123Feature} from '../../../../classes/survey123-feature';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LayerService} from '../../services/layer.service';
import {switchMap} from 'rxjs';
import {MatCardModule} from '@angular/material/card';
import {AsyncPipe, KeyValuePipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../../../../../shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {map} from 'rxjs/operators';
import {Mapster, MapsterProperties} from '../../classes/mapster';
import {MapsterPackageOptions, MapsterPackageOptionsMap} from '../../../../integrations/mapster/models';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ConfirmationComponent} from '../../../../../shared/modals/confirmation/confirmation.component';
import {FormControl, Validators} from '@angular/forms';
import {MapsterService} from '../../../../integrations/mapster/mapster.service';

export const DEFAULT_MAPSTER_OPTIONS: MapsterPackageOptions = {
    image: false,
    imageMetadata: false,
    video: false,
    videoMetadata: false,
    lidar: false,
    pdal: false,
    thumbnail: false,
    uuid: '',
};

@Component({
    standalone: true,
    selector: 'eaglei-mapster-popover',
    templateUrl: './mapster-popover.component.html',
    styleUrls: ['./mapster-popover.component.scss'],
    imports: [
        MatCardModule,
        AsyncPipe,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        NgIf,
        MatTabsModule,
        SharedModule,
        FontAwesomeModule,
        KeyValuePipe,
    ],
})
export class MapsterPopoverComponent extends BasePopover<Mapster> implements OnDestroy {
    private dialog = inject(MatDialog);
    private mapsterService = inject(MapsterService);

    public loading = signal(true);
    public dataOptions: WritableSignal<MapsterPackageOptions> = signal(DEFAULT_MAPSTER_OPTIONS);
    public optionMap = MapsterPackageOptionsMap;
    public optionsForm = new FormControl<string[]>(null, Validators.required);
    public properties!: Mapster;

    constructor() {
        super();
        this.data$
            .pipe(
                takeUntilDestroyed(),
                switchMap((data: Mapster) => {
                    this.properties = data;
                    return this.mapsterService.getPackageOptions(data.mapsterId);
                })
            )
            .subscribe((options: MapsterPackageOptions) => {
                this.dataOptions.set(options);
            });
    }

    // Abstract from the super class
    afterInit() {}

    // Abstract from the super class
    onInit() {}

    // Abstract from the super class, We should look at deprecating in favor of using data$
    updateData() {}

    public openConfirmation() {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: {
                headerText: 'MAPSTER Download',
                message: `This action will build a zip file with the requested information. When built, that file that will be sent to your registered email address. This action can take some time`,
            },
            maxWidth: '600px',
        });

        dialogRef.afterClosed().subscribe((confirm) => {
            if (confirm) {
                const options = DEFAULT_MAPSTER_OPTIONS;
                options.uuid = this.dataOptions().uuid;
                this.optionsForm.value.forEach((opt) => (options[opt] = true));

                this.mapsterService.triggerPackageOptionsDownload(options);
            }
        });
    }
}
