import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ReportService} from '../modules/report/services/report.service';
import {firstValueFrom, of} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReportGuard implements Resolve<any> {
    constructor(private reportService: ReportService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // This only returns true because the map function on the api call sets up all the data needed,
        // so if the call succeeds it can just return true to tell the route guard to load.

        if (Object.keys(ReportService.reports).length !== 0) {
            return Promise.resolve(true);
        }

        return firstValueFrom(this.reportService.getAllReports()).then(() => true);
    }
}
