import {Component, inject, TemplateRef, ViewChild} from '@angular/core';
import {User} from '../../../modules/user/classes/user';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {AbstractControl, AsyncValidatorFn, FormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/authentication.service';
import {DataService} from '../../../services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Poc} from '../../../modules/user/classes/poc';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PhoneNumberPipe} from '../../../../shared/pipes/phone-number.pipe';

@Component({
    selector: 'eaglei-account-request',
    templateUrl: './account-request.component.html',
    styleUrls: ['./account-request.component.scss'],
})
export class AccountRequestComponent {
    @ViewChild('reasonInfo') reasonDialogTemplate: TemplateRef<unknown>;

    private auth = inject(AuthenticationService);
    private dialog = inject(MatDialog);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private popup = inject(MatSnackBar);

    private phoneNumberPipe = new PhoneNumberPipe();

    public pocNeeded: boolean;
    public states = DataService.states.getValue();

    private readonly usernamePattern: RegExp = new RegExp('^[A-Z]+[A-Z0-9._]*$', 'i');

    private readonly textPattern = Validators.pattern(/[.*[\S].*/);
    public personalFormGroup: UntypedFormGroup = new UntypedFormGroup({
        firstName: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        lastName: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        title: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        department: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        organization: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        city: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        state: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        postalCode: new FormControl<string>('', {validators: [Validators.required, this.textPattern, this.postalCodeValidator()]}),
        email: new FormControl<string>(null, {
            validators: [Validators.required, this.textPattern, Validators.email],
            asyncValidators: [this.uniqueEmailValidator()],
        }),
        confirmEmail: new FormControl<string>('', {
            validators: [Validators.required, this.textPattern, this.emailMatchValidator()],
        }),
        telephone: new FormControl<string>('', {
            validators: [Validators.required, this.phoneNumberValidator()],
        }),
    });
    public pocFormGroup: UntypedFormGroup = new UntypedFormGroup({
        name: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        organization: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        department: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        title: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        email: new FormControl<string>('', {
            updateOn: 'blur',
            validators: [Validators.required, this.textPattern, Validators.email],
        }),
        telephone: new FormControl<string>('', {
            validators: [Validators.required, this.textPattern, this.phoneNumberValidator()],
        }),
    });
    public accountFormGroup: UntypedFormGroup = new UntypedFormGroup({
        username: new FormControl<string>('', {
            validators: [Validators.required, this.textPattern, this.usernamePatternValidator()],
            asyncValidators: [this.uniqueUsernameValidator()],
        }),
        password: new FormControl<string>('', {
            validators: [Validators.required],
            asyncValidators: [this.passwordComplexityValidator()],
        }),
        confirm: new FormControl<string>('', {
            validators: [Validators.required, this.passwordMatchValidator()],
        }),
        reason: new FormControl<string>('', {validators: [Validators.required, this.textPattern]}),
        termsOfUse: new FormControl<boolean>(false, [Validators.requiredTrue]),
    });

    public reasonDailogRef?: MatDialogRef<unknown>;

    public showPassword: boolean;
    public showConfirmPassword: boolean;

    private breakpointObserver = inject(BreakpointObserver);
    public onMobile$ = this.breakpointObserver.observe('(max-width: 960px)').pipe(
        startWith({matches: this.breakpointObserver.isMatched('(max-width: 960px)')}),
        debounceTime(100),
        map((state) => state.matches)
    );

    constructor() {
        if (Object.keys(this.activatedRoute.snapshot.queryParams).length > 0) {
            const queryParams = this.activatedRoute.snapshot.queryParams;
            this.personalFormGroup.patchValue({
                firstName: queryParams['fn'],
                lastName: queryParams['ln'],
                email: queryParams['email'],
            });
            this.router.navigate([]);
        }

        this.personalFormGroup.controls.telephone.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: string) => {
            this.personalFormGroup.controls.telephone.setValue(this.phoneNumberPipe.transform(value.match(/\d+/g).join('')), {
                emitEvent: false,
                onlySelf: true,
            });
        });

        this.pocFormGroup.controls.telephone.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: string) => {
            this.pocFormGroup.controls.telephone.setValue(this.phoneNumberPipe.transform(value.match(/\d+/g).join('')), {
                emitEvent: false,
                onlySelf: true,
            });
        });
    }

    openReasonInfo() {
        if (this.reasonDialogTemplate) {
            this.reasonDailogRef = this.dialog.open(this.reasonDialogTemplate);
        }
    }

    // Validator Methods
    /**
     * Checks to see that username is unique
     */
    private uniqueUsernameValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                return this.auth.checkUsernameExists(control.value).pipe(map((res) => (res ? {unique: res} : null)));
            }
            return of(null);
        };
    }

    /**
     * checks to see if the username matches the application requirements
     */
    private usernamePatternValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                return this.usernamePattern.test(control.value) ? null : {complexity: 'Username is invalid'};
            }
            return null;
        };
    }

    /**
     * Checks to see if the password passes the application complexity check
     */
    private passwordComplexityValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                this.accountFormGroup.controls['confirm'].updateValueAndValidity();
                return this.auth.checkPasswordComplexity(control.value).pipe(map((fault) => (fault ? {complexity: fault} : null)));
            }
            return of(null);
        };
    }

    /**
     * Check to see if the password and confirm password fields match.
     */
    private passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                const match = control.value === this.accountFormGroup.controls.password.value;
                return match ? null : {match: 'Passwords do not match'};
            }
            return null;
        };
    }

    // TODO look into making a user validator to handle validator methods

    // noinspection JSMethodCanBeStatic
    /**
     * Checks to see if the postal code is in the format of XXXXX or XXXXX-XXXX
     */
    private postalCodeValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const regex = /^\d{5}(?:-\d{4})?$/;
                const test = regex.test(control.value);
                return test ? null : {format: 'Postal code must be XXXXX or XXXXX-XXXX'};
            }
            return null;
        };
    }

    /**
     * Checks to see if the email address used is already in the database
     */
    private uniqueEmailValidator(): AsyncValidatorFn {
        return (control) => {
            if (this.personalFormGroup) {
                this.personalFormGroup.controls.confirmEmail.updateValueAndValidity();
            }

            if (control.value) {
                return this.auth.checkEmailExists(control.value).pipe(
                    map((exists) => {
                        return !exists ? null : {unique: 'This email is already associated with another account'};
                    })
                );
            }

            return of(null);
        };
    }

    /**
     * Checks to see if the email match the confirm_email field
     */
    private emailMatchValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const match = control.value === this.personalFormGroup.controls.email.value;
                return match ? null : {match: 'Email does not match '};
            } else {
                return null;
            }
        };
    }

    /**
     * checks to see if a phone number is the correct length
     */
    public phoneNumberValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const regex = /^[0-9()-\s]{14}$/;
                return regex.test(control.value) ? null : {length: 'Phone number must be a valid 10 digit number.'};
            }
            return null;
        };
    }

    // API Methods
    /**
     * Requests a new account for the info filled in. If it fails an error is shown to the error, otherwise an email is sent for confirmation
     */
    public requestAccount(): void {
        const accountInfo = this.accountFormGroup.getRawValue();

        const user = new User(this.personalFormGroup.getRawValue());
        user.telephone = user.telephone.match(/\d+/g).join('');
        user.username = accountInfo.username;
        user.password = accountInfo.password;
        user.reason = accountInfo.reason;

        if (this.pocNeeded) {
            user.poc = new Poc(this.pocFormGroup.getRawValue());
            user.poc.telephone = user.poc.telephone.match(/\d+/g).join('');
        }

        this.auth.requestAccount(user).subscribe({
            next: () => {
                this.popup
                    .open(
                        'Your account request was successful. Please check your email inbox for a link to verify your email address',
                        'Okay',
                        {panelClass: 'dialog-success', duration: 2_000}
                    )
                    .afterDismissed()
                    .subscribe(() => this.goBack());
            },
            error: (error: any) => {
                let errorMessage = 'There was an error requesting your account. Click here email the admin for help.';
                let showAction = true;
                if (error.error && error.error.userMessage) {
                    errorMessage = error.error.userMessage;
                    showAction = false;
                }

                const actionText = showAction ? 'email' : '';
                const popRef = this.popup.open(errorMessage, actionText, {panelClass: 'dialog-failure', duration: 5000});

                if (showAction) {
                    popRef.onAction().subscribe(() => this.emailAdmin());
                }

                throw error;
            },
        });
    }

    /**
     * Opens an email to the admin with the subject and body filled in for account request issues
     */
    public emailAdmin(): void {
        const href = 'mailto:eagle-i@ornl.gov';
        const subject = 'Error Requesting EAGLE-I Account';
        const emailTag = document.createElement('a');
        emailTag.href = `${href}?subject=${subject}`;

        document.body.appendChild(emailTag);
        emailTag.click();
        document.body.removeChild(emailTag);
    }

    // Helper Methods
    /**
     * Navigates back to the login page
     */
    public goBack(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }

    /**
     * Checks the email field and determines if POC information is needed.
     */
    public checkPocNeeded(): void {
        const email = this.personalFormGroup.get('email').value;
        if (!!email) {
            this.pocNeeded = !User.nonPocEmailExtension.some((ext) => email.endsWith(ext));
        }
    }
}
