import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MapsterPackageOptions} from './models';
import {Observable, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MapsterService {
    private http = inject(HttpClient);
    private snackBar = inject(MatSnackBar);
    constructor() {}

    public triggerPackageOptionsDownload(options: MapsterPackageOptions) {
        this.http
            .post('api/mapster/package', options)
            .pipe(
                switchMap((response: MapsterPackageOptions) => {
                    return this.http.post('api/mapster/download-package', options);
                })
            )
            .subscribe({
                next: (res) => {
                    this.snackBar.open('Your download is being prepared and will be emailed shortly', 'X', {
                        panelClass: 'dialog-success',
                    });
                },
                error: (err) => {
                    console.error(err);
                    this.snackBar.open('There was an issue preparing your download', 'X', {
                        panelClass: 'dialog-error',
                    });
                },
            });
    }

    public getPackageOptions(id: string): Observable<MapsterPackageOptions> {
        return this.http.get<MapsterPackageOptions>(`api/mapster/package-options/${id}`);
    }
}
