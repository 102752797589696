export interface MapsterPackageOptions {
    image: boolean;
    imageMetadata: boolean;
    video: boolean;
    videoMetadata: boolean;
    lidar: boolean;
    pdal: boolean;
    thumbnail: boolean;
    uuid: string;
}

export const MapsterPackageOptionsMap = {
    image: 'Image',
    imageMetadata: 'Image Metadata',
    video: 'Video',
    videoMetadata: 'Video Metadata',
    lidar: 'Lidar',
    pdal: 'PDAL',
    thumbnail: 'Thumbnail',
    uuid: 'UUID',
};
